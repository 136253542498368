<template>
  <div class="form pt-6">
    <form @submit.prevent="submit" novalidate>
      <div class="flex justify-center my-6">
        <div class="px-4">
          <name-component v-model="form.name" :v="$v.form.name" />
          <os-component v-model="form.os_selected" />
          <vlan-component v-model="form.network_selected" />
          <b-card-group class="mt-3">
            <memory-component v-model="form.memory" :v="$v.form.memory" />
            <disk-component v-model="form.disk" :v="$v.form.disk"
          /></b-card-group>
          <b-card class="mt-3 component-margin" header-tag="header">
            <template #header>
              <div>
                <div class="child inline-block-child">
                  <img
                    v-b-tooltip.hover
                    title="CPU"
                    width="20px"
                    src="../../../assets/images/cpu.svg"
                  />
                </div>
                <div class="child inline-block-child">
                  <h6 class="mb-0">CPU</h6>
                </div>
              </div>
            </template>
            <core-component v-model="form.cores" />
            <socket-component v-model="form.sockets" />
          </b-card>
        </div>
        <div class="px-4">
          <b-button block @click="submit()" variant="primary">Crear</b-button>
        </div>
      </div>
    </form>
    <transition name="fade">
      <Loading v-if="busy" />
    </transition>
  </div>
</template>

<script>
import { required, minLength, between } from "vuelidate/lib/validators";
import NameComponent from "./NameComponent.vue";
import VlanComponent from "./VlanComponent.vue";
import OsComponent from "./OSComponent.vue";
import MemoryComponent from "./MemoryComponent.vue";
import DiskComponent from "./DiskComponent.vue";
import CoreComponent from "./CoreComponent.vue";
import SocketComponent from "./SocketComponent.vue";

export default {
  name: "QemuForm",
  components: {
    NameComponent,
    VlanComponent,
    OsComponent,
    MemoryComponent,
    DiskComponent,
    CoreComponent,
    SocketComponent,
  },
  created() {
    this.getQemus();
  },
  data() {
    return {
      busy: false,
      form: {
        name: "",
        network_selected: {},
        os_selected: {},
        memory: "1024",
        disk: "1",
        cores: 1,
        sockets: 1,
      },
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(4) },
      memory: { required, between: between(1024, 8192) },
      disk: { required, between: between(1, 30) },
    },
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (
        this.$v.form.$pending ||
        this.$v.form.$error ||
        this.$v.form.$invalid ||
        this.isEmpty(this.form.os_selected) ||
        this.isEmpty(this.form.network_selected)
      ) {
        this.makeToast("danger", "Revisa el formulari");
        return;
      }
      this.newVM();
    },
    async newVM() {
      if (this.$store.state.qemus.length >= process.env.VUE_APP_MAX_QEMUS) {
        this.makeToast("danger", "Màxim 10 màquines");
        return;
      }

      this.busy = true;
      var node = this.$getNodeOfStudent(this.$store.state.user.pool);
      var vm = {
        name: this.$formatQemuName(this.form.name),
        ide2: this.form.os_selected.value + ",media=cdrom",
        pool: this.$store.state.user.pool,
        ostype: this.getOsType(this.form.os_selected.value),
        scsihw: "virtio-scsi-pci",
        sockets: this.form.sockets,
        sata0: "PoolAlumnat:" + this.form.disk,
        cores: this.form.cores,
        memory: this.form.memory,
        net0:
          "virtio,bridge=vmbr" +
          `${this.form.network_selected.value}` +
          ",firewall=1",
        balloon: "512",
        vga: "type=std,memory=32",
      };
      await this.$apiCloud("nodes/" + `${node}` + "/qemu", {
        method: "POST",
        body: new URLSearchParams(Object.entries(vm)).toString(),
      })
        .then((response) => {
          this.busy = false;
          this.makeToast("success", "Màquina creada");
          setTimeout(() => {
            this.$router.push("dashboard");
          }, 2000);
        })
        .catch((err) => {
          this.busy = false;
          this.makeToast("danger", "No hem pogut crear la màquina");
        });
    },
    getOsType(isoImage) {
      if (isoImage.includes("win") || isoImage.includes("Win")) {
        return "win10";
      } else if (isoImage.includes("ubuntu") || isoImage.includes("linux")) {
        return "l26";
      } else {
        return "l26";
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    async getQemus() {
      await this.$apiCloud("vms", {
        method: "GET",
      })
        .then((response) => {
          this.$store.commit("setQemus", response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    makeToast(variant, msg) {
      this.$bvToast.toast(msg, {
        title: "Virtualio.",
        variant: variant,
        toaster: "b-toaster-top-full",
        solid: true,
        autoHideDelay: 5000,
      });
    },
  },
};
</script>
<style scoped>
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
.component-margin {
  margin-bottom: 25px;
}
</style>