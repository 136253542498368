<template>
  <div class="dashboard-container">
    <b-container>
      <br />
      <b-row>
        <b-col>
          <h2 class="titles">Virtual Machines</h2>
          <hr />
        </b-col>
      </b-row>
      <b-row> <b-col>
          <div style="float: right">
            <b-button href="/bundle">Nova Màquina</b-button><br />
            <br />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div>
            <b-overlay :show="loading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th></th>
                    <th>Description</th>
                    <th>Disk</th>
                    <th>Memory usage</th>
                    <th>CPU usage</th>
                    <th>Uptime</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, i) in qemus" :key="i">
                    <th scope="row">
                      <img width="20px" src="../../assets/images/vm.svg" v-bind:class="[
                        item.status == 'running' ? '' : 'filter-gray',
                      ]" /><i><span style="display: inline-block; width: 10px"></span>
                        {{ item.status }}</i>
                    </th>
                    <td @click="openQemu(item.vmid, item.node)" style="cursor: pointer">
                      {{ item.name }}
                    </td>
                    <td>{{ getDiskUsage(item.disk, item.maxdisk) }}</td>
                    <td>{{ getMemoryUsage(item.mem, item.maxmem) }}</td>
                    <td>{{ getCpuUsage(item.cpu, item.cpus) }}</td>
                    <td>{{ getTime(item.uptime) }}</td>
                    <td>
                      <img v-b-tooltip.hover title="Start virtual machine" width="15px" class="pointer" v-bind:class="[
                        item.status == 'stopped'
                          ? 'filter-green'
                          : 'filter-dark-gray',
                      ]" @click="changeStatus(item.vmid, item.node, 'start')" alt="start"
                        src="../../assets/images/start.svg" /><span style="display: inline-block; width: 10px"></span>
                      <img v-b-tooltip.hover title="Stop virtual machine" width="15px" class="pointer" v-bind:class="[
                        item.status == 'running'
                          ? 'filter-red'
                          : 'filter-dark-gray',
                      ]" @click="changeStatus(item.vmid, item.node, 'stop')" src="../../assets/images/stop.svg" /><span
                        style="display: inline-block; width: 10px"></span>
                      <img v-b-tooltip.hover
                        title="Expulsar CD. Si la màquina conté una imatge a l'unitat de CD no estarà en alta disponibilitat."
                        width="18px" class="pointer" src="../../assets/images/exect.svg"
                        @click="exectCD(item.vmid, item.node)" /><span
                        style="display: inline-block; width: 10px"></span>
                      <img v-b-tooltip.hover title="Open terminal virtual machine" width="18px" class="pointer"
                        @click="openVNC(item.vmid, item.node)" src="../../assets/images/logo_powershell.svg"
                        v-bind:class="[
                          item.status == 'running' ? '' : 'filter-gray',
                        ]" /><span style="display: inline-block; width: 10px"></span>
                      <img v-b-tooltip.hover title="Delete virtual machine" width="18px" class="pointer"
                        src="../../assets/images/delete.svg" @click="showConfirmation(item)" /><span
                        style="display: inline-block; width: 10px"></span>
                      <img v-b-tooltip.hover title="Edit virtual machine" width="18px" class="pointer"
                        src="../../assets/images/code-edit.svg" @click="openQemu(item.vmid, item.node)" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-overlay>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import loginMixin from "../../mixins/login.js";

export default {
  name: "Dashboard",
  mixins: [loginMixin],
  components: {},
  data() {
    return {
      items: [],
      result: "",
      timer: "",
      loading: false,
    };
  },
  computed: {
    qemus() {
      return this.$store.state.qemus;
    },
  },
  created() {
    this.getQemus(true);
    //this.timer = setInterval(this.getQemus, 10000);
  },
  methods: {
    async getPoolsTest() {
      this.result = await this.getPools();
    },
    async openVNC(vmid, node) {
      var response = await this.$apiCloud(
        "nodes/" + `${node}` + "/qemu/" + `${vmid}` + "/no-vnc",
        {
          method: "GET",
        }
      );
      var url = response.url;
      var nomCookie = response.nomCookie;
      var valorCookie = response.valorCookie;
      var expira = new Date();
      expira.setMonth(expira.getMonth() + 12);
      var domini = response.domini;
      window.$cookies.set(nomCookie, valorCookie, expira, "/", domini);
      window.open(url);
    },
    async changeStatus(vmid, node, status) {
      this.loading = true;

      if (status == "start") {
        let max = 0;
        this.qemus.forEach((qemu) => {
          if (qemu.status == "running") max++;
        });
        if (max >= process.env.VUE_APP_MAX_QEMUS_RUNNING) {
          this.makeToast(
            "warning",
            "Màxim " +
            `${process.env.VUE_APP_MAX_QEMUS_RUNNING}` +
            " màquines arrancades a la vegada"
          );
          this.loading = false;
          return;
        }
      }

      await this.$apiCloud(
        "nodes/" + `${node}` + "/qemu/" + `${vmid}` + "/status/" + `${status}`,
        {
          method: "POST",
        }
      )
        .then((response) => {
          this.makeToast(
            "warning",
            "Canviant estat de la màquina, tarda uns segons."
          );
          this.loading = false;
          this.getQemus(true);
        })
        .catch((err) => {
          this.makeToast("danger", "No hem pogut realitzar l'operació");
        });
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
    makeToast(variant, msg) {
      this.toastCount++;
      this.$bvToast.toast(msg, {
        title: "Virtualio.",
        variant: variant,
        solid: true,
        autoHideDelay: 5000,
      });
    },
    openQemu(vmid, node) {
      this.$router.push({
        name: "qemu_edit",
        params: { id: vmid, node: node },
      });
    },
    async getQemus(loading = false) {
      this.loading = loading;
      await this.$apiCloud("vms", {
        method: "GET",
      })
        .then((response) => {
          this.$store.commit("setQemus", response.data);
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTime(seconds) {
      return new Date(seconds * 1000).toISOString().substr(11, 8);
    },
    getDiskUsage(disk, max) {
      return this.formatBytes(max);
    },
    getMemoryUsage(mem, max) {
      return this.formatBytes(mem) + " / " + this.formatBytes(max);
    },
    getCpuUsage(cpu, cpus) {
      return Math.round(cpu * 100) + " %";
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    },
    showConfirmation(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Per favor confirmes que vols eliminar la màquina:   " + item.name,
          {
            title: "Confirmació",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "SÍ",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.deleteQemu(item);
          }
        })
        .catch((err) => {
          this.makeToast("danger", "No hem pogut elimnar la màquina");
        });
    },
    async exectCD(vmid, node) {
      let cd = {
        ide2: "none,media=cdrom",
      };
      this.loading = true;
      await this.$apiCloud(
        "nodes/" +
        `${node}` +
        "/qemu/" +
        `${vmid}` +
        "/config",
        {
          method: "PUT",
          body: new URLSearchParams(Object.entries(cd)).toString(),
        }
      )
        .then((response) => {
          this.loading = false;
          this.makeToast("success", "Recurs actualitzat correctament");
        })
        .catch((err) => {
          this.loading = false;
          this.makeToast(
            "danger",
            "No hem pogut actulizar els recursos de la màquina."
          );
        });
    },
    async deleteQemu(item) {
      this.loading = true;
      await this.$apiCloud(
        "nodes/" + `${item.node}` + "/qemu/" + `${item.vmid}`,
        {
          method: "DELETE",
        }
      )
        .then((response) => {
          this.loading = false;
          this.makeToast("success", "Màquina eliminada");
        })
        .catch((err) => {
          this.loading = false;
          this.makeToast(
            "danger",
            "No hem pogut elimnar la màquina. Recorda que ha d'estar parada per poder-la eliminar."
          );
        });
    },
  },
  beforeDestroy() {
    this.cancelAutoUpdate();
  },
};
</script>

<style lang="scss" scoped >
.filter-gray {
  filter: invert(99%) sepia(3%) saturate(6%) hue-rotate(269deg) brightness(107%) contrast(85%);
}

.filter-dark-gray {
  filter: invert(41%) sepia(4%) saturate(8%) hue-rotate(326deg) brightness(95%) contrast(83%);
}

.filter-red {
  filter: invert(54%) sepia(42%) saturate(3824%) hue-rotate(325deg) brightness(97%) contrast(83%);
}

.filter-green {
  filter: invert(99%) sepia(4%) saturate(4851%) hue-rotate(40deg) brightness(84%) contrast(87%);
}
</style>
