<template>
  <b-card class="mt-3" header-tag="header">
    <template #header>
      <div>
        <div class="child inline-block-child">
          <img
            v-b-tooltip.hover
            title="General"
            width="20px"
            src="../../../assets/images/Images.svg"
          />
        </div>
        <div class="child inline-block-child">
          <h6 class="mb-0">General</h6>
        </div>
      </div>
    </template>
    <div :class="{ hasError: v.$error }">
      <b-form-group
        label="Nom:"
        label-for="name"
        label-cols-sm="2"
        label-align-sm="right"
      >
        <b-form-input
          id="name"
          class="input"
          v-model="name"
          placeholder="Nom"
          @input="v.$touch()"
        ></b-form-input>
        <label class="placeholderError">
          Nom obligatori i mínm de longitud quatre caràcters
        </label>
      </b-form-group>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    name: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
</style>