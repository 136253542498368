<template>
  <div class="dashboard-container">
    <b-container>
      <b-row>
        <br />
      </b-row>
      <b-row>
        <b-col
          ><h2 class="titles">Serveis</h2>
          <hr
        /></b-col>
      </b-row>
      <b-row>
        <br />
      </b-row>
      <b-row>
        <b-col>
          <b-card
            title="Nova VM"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  width="20px"
                  src="../../assets/images/vm.svg"
                  alt="VM"
                />
              </div>
              <div>
                <a href="#" class="server-text" aria-label="VM">Nova màquina</a>
                <div class="text-info">
                  <a class="pointer" @click="newVM()"> Crear</a>
                  |
                  <a class="pointer" id="new_vm">Més informació</a>
                </div>
                <b-tooltip target="new_vm" triggers="hover">
                  No disponible de moment. Crea una nova màquina amb la
                  configuració que vulguis<br />
                </b-tooltip>
              </div>
            </div> </b-card
        ></b-col>
        <b-col>
          <b-card
            title="WINDOWS SERVER 2019"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  src="../../assets/windows.png"
                  alt="Windows Server"
                />
              </div>
              <div>
                <a href="#" class="server-text" aria-label="Windows Server"
                  >Windows Server</a
                >
                <div class="text-info">
                  <a class="pointer" @click="modalBundle('windowsServer')"
                    >Crear</a
                  >
                  |
                  <a class="pointer" id="windows_server">Més informació</a>
                </div>
                <b-tooltip target="windows_server" triggers="hover">
                  CPU: 2 SOCKET / 3 CORE <br />RAM: 4096 MB <br />
                  HDD 30GB SISTEMA <br />
                </b-tooltip>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            title="UBUNTU SERVER 20.04"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  src="../../assets/ubuntu.png"
                  alt="UBUNTU SERVER 20.04"
                />
              </div>
              <div>
                <a class="server-text" aria-label="UBUNTU SERVER 20.04"
                  >Ubuntu Server</a
                >
                <div class="text-info">
                  <a class="pointer" @click="modalBundle('ubuntuServer')"
                    >Crear</a
                  >
                  |
                  <a class="pointer" id="ubuntu_server">Més informació</a>
                </div>
                <b-tooltip target="ubuntu_server" triggers="hover">
                  CPU: 2 SOCKET / 2 CORE <br />RAM: 4096 MB <br />
                  HDD 20GB SISTEMA <br />
                </b-tooltip>
              </div>
            </div> </b-card
        ></b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card
            title="WINDOWS 10"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  src="../../assets/windows.png"
                  alt="Windows Server"
                />
              </div>
              <div>
                <a href="#" class="server-text" aria-label="Windows 10"
                  >Windows 10</a
                >
                <div class="text-info">
                  <a class="pointer" @click="modalBundle('windows10')">Crear</a>
                  |
                  <a class="pointer" id="windows_10">Més informació</a>
                </div>
                <b-tooltip target="windows_10" triggers="hover">
                  CPU: 2 SOCKET / 4 CORE <br />RAM: 8192 MB <br />
                  HDD 30GB SISTEMA <br />
                </b-tooltip>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col>
          <b-card
            title="UBUNTU DESKTOP 20.04"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  src="../../assets/ubuntu.png"
                  alt="Windows Server"
                />
              </div>
              <div>
                <a href="#" class="server-text" aria-label="Windows Server"
                  >Ubuntu Desktop</a
                >
                <div class="text-info">
                  <a class="pointer" @click="modalBundle('ubuntuDesktop')"
                    >Crear</a
                  >
                  |
                  <a class="pointer" id="ubuntu_desktop">Més informació</a>
                </div>
                <b-tooltip target="ubuntu_desktop" triggers="hover">
                  CPU: 2 SOCKET / 2 CORE <br />RAM: 4096 MB <br />
                  HDD 20GB SISTEMA <br />
                </b-tooltip>
              </div>
            </div> </b-card
        ></b-col>
        <b-col>
          <b-card
            title="PFSENSE"
            tag="article"
            style="max-width: 20rem"
            class="mb-2"
          >
            <div class="service">
              <div>
                <img
                  class="item-icon"
                  src="../../assets/pf.png"
                  alt="Windows Server"
                />
              </div>
              <div>
                <a href="#" class="server-text" aria-label="PF Sense"
                  >PFSENSE</a
                >
                <div class="text-info">
                  <a class="pointer" @click="modalBundle('pfSense')">Crear</a>
                  |
                  <a class="pointer" id="pfsense">Més informació</a>
                </div>
                <b-tooltip target="pfsense" triggers="hover">
                  CPU: 2 SOCKET / 2 CORE <br />RAM: 2048 MB <br />
                  HDD 12GB SISTEMA <br />
                </b-tooltip>
              </div>
            </div> </b-card
        ></b-col>
      </b-row>
    </b-container>
    <b-modal
      id="newBundle"
      title="Nova màquina"
      @ok="addQemu"
      @hidden="resetModal"
      size="lg"
    >
      <h4>{{ this.bundle_selected.name }}</h4>
      <div class="d-block">
        <name-component v-model="form.name" :v="$v.form.name" />
        <vlan-component v-model="form.network_selected" />
      </div>
    </b-modal>
    <transition name="fade">
      <Loading v-if="busy" />
    </transition>
  </div>
</template>
<script>
import {
  ubuntuDesktop,
  ubuntuServer,
  windowsServer,
  windows10,
  pfSense,
} from "./bundles";
import { required, minLength } from "vuelidate/lib/validators";
import VlanComponent from "../qemu/components/VlanComponent.vue";
import NameComponent from "../qemu/components/NameComponent.vue";
export default {
  name: "Bundle",
  computed: {
    routeName() {
      let name = "bundle";
      return this.capitalizeFirstLetter(name);
    },
  },
  data() {
    return {
      busy: false,
      bundle_selected: {},
      form: {
        name: "",
        network_selected: {},
      },
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(4) },
    },
  },
  components: {
    VlanComponent,
    NameComponent,
  },
  methods: {
    async newVM() {
      this.makeToast("danger", "No disponible");
      /* Compentat de moment no deixem crear màquines sense bundle
      this.$router.push({
        path: "qemu",
      });*/
    },
    async modalBundle(bundle) {
      this.bundle_selected = this.getBundle(bundle);
      this.$bvModal.show("newBundle");
    },
    async addQemu() {
      var vm = this.bundle_selected;
      vm.name = this.form.name;
      vm.pool = this.$store.state.user.pool;
      if (!this.isEmpty(this.form.network_selected)) {
        vm.net0 =
          "e1000,bridge=vmbr" +
          `${this.form.network_selected.value}` +
          ",firewall=0";
      }
      this.createBundle(vm);
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    getBundle(bundle) {
      switch (bundle) {
        case "ubuntuDesktop":
          return ubuntuDesktop;
        case "ubuntuServer":
          return ubuntuServer;
        case "windowsServer":
          return windowsServer;
        case "windows10":
          return windows10;
        case "pfSense":
          return pfSense;
      }
    },
    async createBundle(vm) {
      if (this.$store.state.qemus.length >= process.env.VUE_APP_MAX_QEMUS) {
        this.makeToast("danger", "Màxim 12 màquines");
        return;
      }
      this.busy = true;
      var node = this.$getNodeOfStudent(this.$store.state.user.pool);
      vm.name = this.$formatQemuName(vm.name);
      await this.$apiCloud("nodes/" + `${node}` + "/qemu", {
        method: "POST",
        body: new URLSearchParams(Object.entries(vm)).toString(),
      })
        .then((response) => {
          this.busy = false;
          this.makeToast("success", "Màquina creada");
          setTimeout(() => {
            this.$router.push("dashboard");
          }, 1000);
        })
        .catch((err) => {
          this.busy = false;
          this.makeToast("danger", "No hem pogut crear la màquina");
        });
    },
    makeToast(variant, msg) {
      this.toastCount++;
      this.$bvToast.toast(msg, {
        title: "Virtualio.",
        variant: variant,
        solid: true,
        autoHideDelay: 5000,
      });
    },
    resetModal() {
      this.bundle_selected = {};
      this.form.name = "";
      this.form.network_selected = {};
    },
  },
};
</script>
<style lang="scss" scoped>
.card-body {
  height: 195px;
}
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
.dashboard-container {
  height: auto;
}
.service {
  line-height: 20px;
}
.item-icon {
  height: 48px;
  width: 48px;
}
.server-text {
  color: rgb(37, 37, 37);
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 2px;
  font-weight: 500;
  display: block;
}
.text-info {
  color: #0078d4;
  font-size: small;
}
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
