export function $formatQemuName(s) {
    var r = s.toLowerCase();
    var non_asciis = {
        a: "[àáâãäå]",
        ae: "æ",
        c: "ç",
        e: "[èéêë]",
        i: "[ìíîï]",
        n: "ñ",
        o: "[òóôõö]",
        oe: "œ",
        u: "[ùúûűü]",
        y: "[ýÿ]",
        x100: "[%]",
        _:"[.]"
    };
    for (var i in non_asciis) {
        r = r.replace(new RegExp(non_asciis[i], "g"), i);
    }
    return r.replace(/\s/g, "-");
}

export default {
    install(Vue) {
        Vue.prototype.$formatQemuName = $formatQemuName
    }
}

export {$formatQemuName as formatQemuName}
