import axios from "axios";
export default {
    methods: {
        async login() {
            var response = "";
            const authUrl = `ldap_username=${this.loginInput.username}&ldap_password=${this.loginInput.password}`
            const options = {
                method: 'POST',
                data: authUrl,
                url: process.env.VUE_APP_API + '/login',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            await axios(options).then((response) => {
                this.$store.commit("setUser", response.data);
                this.$router.push("/dashboard");
            }).catch(err => {
                response = err.response;
            });
            return response;
        },
        // pools
        // https://pve.proxmox.com/pve-docs/api-viewer/index.html#/pools
        async getPools() {

            window.open('https://185.13.77.249:8006/?console=kvm&novnc=1&vmid=100&vmname=win2019&node=prox1&resize=off&cmd=');
            // return await this.get('/pools')
        },
        async get(url, param) {

            var result;

            const options = {
                method: 'GET',
                url: process.env.VUE_APP_API + url,
                params: param,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'CSRFPreventionToken': window.$cookies.get('user').csrfToken,
                    'Cookie': window.$cookies.get('user').ticket
                }
            }

            await axios(options)
                .then(res => {
                    result = res.data.data
                })
                .catch(err => {
                    result = err.response.status
                })

            return result;
        }
    }
};
