let baseUrl;
import axios from "axios";
export async function $apiCloud(url, options) {
    
    const token = this.$cookies.get('token');
    
    const finalOptions = Object.assign({}, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Bearer ' + token
        },
    }, options)

    const response = await fetch(`${baseUrl}` + "/" + `${url}`, finalOptions)
    if (response.ok) {
        const data = await response.json()
        return data
    } else if (response.status == 412) {
        return 'No se ha podido realizar la operación. Revise los datos.';
    } else if (response.status == 400) {
        const error = new Error('Nombre de usuario o contraseña incorrectos.')
        throw error
    } else if (response.status == 409) {
        var responseObject = { status: 409, msg: 'Este email ya ha sido registrado. ¿Quieres iniciar sesión?' }
        return responseObject;
    } else {
        const error = new Error('No se ha podido realizar la operación. Revise los datos.')
        throw error
    }
}
export default {
    install(Vue, options) {
        // Plugin options
        baseUrl = options.baseUrl
        // Fetch
        Vue.prototype.$apiCloud = $apiCloud
    },
}

export { $apiCloud as apiCloud }
