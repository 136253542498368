export function $getNodeOfStudent(student) {
    try {
        var id = parseInt(student.substring(6));
        if (id <= 10)
            return 'node1';
        else if (id >=11  && id <= 20)
            return 'node2';
        else if (id >= 21 && id <= 30)
            return 'node3';
        else if (id >= 31 && id <= 40)
            return 'node4';
        else if (id >= 41 && id <= 50)
            return 'node5';
        else if (id >= 51 && id <= 60)
            return 'node6';
        else if (id >= 61 && id <= 70)
            return 'node1-c2';
        else if (id >= 71 && id <= 80)
            return 'node2-c2';
        else if (id >= 81 && id <= 90)
            return 'node3-c2';
        else if (id >= 91 && id <= 100)
            return 'node4-c2';
        else if (id >= 101)
            return 'node5-c2';

    } catch (err) {
        return 'node1';
    }
}
export default {
    install(Vue) {
        Vue.prototype.$getNodeOfStudent = $getNodeOfStudent
    }
}

export { $getNodeOfStudent as getNodeOfStudent }
