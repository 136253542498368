import Vue from 'vue';
import Router from 'vue-router';
import Login from '../modules/login/LoginPage.vue';
import Dashboard from '../modules/dashboard/DashboardPage.vue';
import Qemu from '../modules/qemu/QemuPage.vue';
import QemuEdit from '../modules/qemu/QemuEditPage.vue';
import Bundle from '../modules/bundles/BundlePage.vue';
import NotFound from '../modules/pages/Page404.vue';
Vue.use(Router);

const routes = [
    {
        path: '/',
        redirect: {
            name: "dashboard"
        }
    },
    { path: '/login', component: Login, name: 'login' },
    { path: '/dashboard', component: Dashboard, name: 'dashboard', meta: { private: true } },
  /*  { path: '/qemu', component: Qemu, name: 'qemu', meta: { private: true } },*/
    { path: '/qemu/:id/node/:node', component: QemuEdit, name: 'qemu_edit', meta: { private: true } },
    { path: '/bundle', component: Bundle, name: 'bundle', meta: { private: true } },
    { path: '*', component: NotFound }
];

export function createRouter() {
    const router = new Router({
        routes,
        mode: 'history',
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition
            }
            if (to.hash) {
                return { selector: to.hash }
            }
            return { x: 0, y: 0 }
        }
    });

    router.beforeEach((to, from, next) => {
        var authenticated = false;

        if ((window.$cookies.get('isAuthenticated') != null)) {
            authenticated = true;
        }
        if (to.meta.private && !authenticated) {
            next({
                name: 'login',
                params: {
                    wantedRoute: to.fullPath,
                },
            })
            return
        } else if (to.name == 'login' && authenticated) {
            next({
                name: 'dashboard'
            })
        }
        next()
    });

    return router;
}
