 <template>
  <div class="footer">
    <b-container class="bv-example-row">
     <!-- <b-row>
        <b-col>1 of 3</b-col>
        <b-col>2 of 3</b-col>
        <b-col>3 of 3</b-col>
      </b-row>-->
      <b-row>
        <b-col> <div class="text-block-117">© Ins Montsià 2023</div></b-col>
      </b-row>
    </b-container>
  </div>
</template>
  <script>
export default {
  name: "Footer",
};
</script>
<style scoped>
.footer-link {
  text-decoration: none;
  color: #212529;
  line-height: 20px;
  margin-right: 0px;
  margin-bottom: 10px;
  font-weight: 200;
  font-size: 17px;
}
.footer {
  -webkit-text-size-adjust: 100%;
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #212529;
  font-size: 16px;
  line-height: 20px;
  box-sizing: border-box;
  display: flex;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  background-color: #f1f2f3;
}
.footer-link:hover {
  text-decoration: underline;
}
.columns-15 {
  -webkit-text-size-adjust: 100%;
  color: #212529;
  font-size: 16px;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  width: 90%;
  margin-right: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
}
.column-22 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.link-block-16 {
  -webkit-text-size-adjust: 100%;

  font-size: 16px;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  background-color: transparent;
  max-width: 100%;
  display: inline-block;
  color: #212529;
  text-decoration: none;
}
.image-98 {
  -webkit-text-size-adjust: 100%;
  font-family: "Neuehaasgroteskdisp w01", sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  max-width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}
.w-inline-block {
  -webkit-text-size-adjust: 100%;
  font-family: "Neuehaasgroteskdisp w01", sans-serif;
  font-size: 16px;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  background-color: transparent;
  text-decoration: underline;
  max-width: 100%;
  display: inline-block;
  text-decoration: none;
  color: #212529;
  line-height: 20px;
}
.text-block-17 {
  -webkit-text-size-adjust: 100%;

  color: #212529;
  line-height: 20px;
  -webkit-box-direction: normal;
  box-sizing: border-box;
  font-size: 14px;
  font-weight: 300;
}

.text-block-117 {
  font-size: 17px;
  font-weight: 300;
}
.text-block-118 {
  color: #323c49;
  font-size: 17px;
  font-weight: 500;
}
.text-block-118.a {
  margin-top: 0px;
  margin-bottom: 20px;
}
.w-inline-block {
  max-width: 100%;
  display: inline-block;
}
.link-block-16 {
  color: #212529;
  text-decoration: none;
}
</style>