
export const setUser = (state, res) => {

    state.user.name = res.username;
    state.user.ticket = res.access_token;
    state.user.tokenTimeStamp = new Date().getTime();
    state.isAuthenticated = true;
    state.user.pool = res.pool;
    window.$cookies.set('isAuthenticated', 'true', "1D");
    window.$cookies.set('name',res.username);
    window.$cookies.set('pool',res.pool);
    window.$cookies.set('token', state.user.ticket);
};

export const getUser = (state, user) => {
    state.user.name = window.$cookies.get('name') != null ? window.$cookies.get('name') : '';
    state.user.pool = window.$cookies.get('pool') != null ? window.$cookies.get('pool') : '';
    state.user.ticket = window.$cookies.get('token') != null ? window.$cookies.get('token') : '';
    state.isAuthenticated = window.$cookies.get('token') != null;
};

export const setQemus = (state, qemus) => {
    state.qemus = [];
    qemus.forEach(nodes => {
        nodes.qemus.forEach(qemu => {
            qemu.node = nodes.node;
            state.qemus.push(qemu);
        });
    });
    state.qemus.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        // names must be equal
        return 0;
    });
};
export const logout = (state, playload) => {  
    state.user.ticket = '';
    state.isAuthenticated = false;
    state.user.tokenTimeStamp ='';
    state.user.name = '';
    state.user.pool = '';
    window.$cookies.remove('isAuthenticated');
    window.$cookies.remove('token');
    window.$cookies.remove('pool');
    window.$cookies.remove('name');
}