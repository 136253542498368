<template>
  <div id="app">
    <template v-if="isAuthenticated">
      <Header />
    </template>
    <template>
      <div class="content">
        <transition  name="slide-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </template>
    <template v-if="isAuthenticated">
      <Footer />
    </template>
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
import Footer from "../layout/Footer.vue";

import { mapGetters } from "vuex";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
      user: "user",
    }),
  },
  created() {
    this.$store.commit("getUser", "");
  },
};
</script>

<style lang="scss" >
@import "../assets/styles/cloud.scss";
#app {
  font-family: az_ea_font, "Segoe UI", az_font, system-ui, -apple-system,
    BlinkMacSystemFont, Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.content {
  height: 1900px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s linear;
}
.titles {
  text-align: start;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active {
    transition: all 0.3s ease;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}
</style>
