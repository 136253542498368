import { fetchQemus } from '../api/mock';
const baseURL = process.env.VUE_APP_API;
import axios from 'axios'
export const getList = ({ commit }, id) => {
    /* return axios.get(baseURL + '/list/' + id).then(response => {
         commit("setMyList", response.data);
     }).catch(err => {
         console.log(err);
     });*/
}
export const getQemus = ({ commit }) => {
    // return fetchQemus().then(qemus => commit('setQemus', qemus))
   
/*
    return axios.get(baseURL + '/vms').then(response => {
        console.log(response);
        commit("setQemus", response.data);
    }).catch(err => {
        console.log(err);
    });*/
}

