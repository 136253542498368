<template>
  <b-card class="mt-3" header-tag="header">
    <template #header>
      <div>
        <div class="child inline-block-child">
          <img
            v-b-tooltip.hover
            title="OS"
            width="30px"
            src="../../../assets/images/container.svg"
          />
        </div>
        <div class="child inline-block-child">
          <h6 class="mb-0">OS</h6>
        </div>
      </div>
    </template>
    <b-form-group
      label="ISO image:"
      label-for="nested-street"
      label-cols-sm="2"
      label-align-sm="right"
    >
      <v-select v-model="os_selected" :loading=busy :label="os.label" :options="os"></v-select>
    </b-form-group>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },
  data() {
    return {
      os: [],
      busy: true
    };
  },
  created: function () {
    this.getISOImages();
  },
  computed: {
    os_selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    async getISOImages() {
      var response = await this.$apiCloud("nodes/node1/iso-images", {
        method: "GET",
      });
      response.data.forEach((iso) => {
        this.os.push({ label: iso.volid,value: iso.volid });
        this.os.sort(function (a, b) {
          var nameA = a.label.toUpperCase();
          var nameB = b.label.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
      });
      this.busy = false;
    },
  },
};
</script>
<style scoped>
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
</style>