import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import Vuex from 'vuex'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import App from './layout/App.vue'
import VueRouter from 'vue-router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { createRouter } from './router'
import { createStore } from './store'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies'
import CloudApi from './plugins/api'
import FormatQemuName from './plugins/string'
import GetNodeStudent from './plugins/roundrobin'
import Vuelidate from 'vuelidate'
import './global-components'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'


Vue.config.productionTip = false

Vue.use(CloudApi, {
  baseUrl: process.env.VUE_APP_API
});
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuex)
Vue.use(VueApexCharts)
Vue.use(VueCookies)
Vue.use(FormatQemuName)
Vue.use(GetNodeStudent)
Vue.use(Vuelidate)
Vue.component('v-select', vSelect)
const router = createRouter();
const store = createStore();
// sync so that route state is available as part of the store
sync(store, router);

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
