var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('b-container',[_c('br'),_c('b-row',[_c('b-col',[_c('h2',{staticClass:"titles"},[_vm._v("Virtual Machines")]),_c('hr')])],1),_c('b-row',[_c('b-col',[_c('div',{staticStyle:{"float":"right"}},[_c('b-button',{attrs:{"href":"/bundle"}},[_vm._v("Nova Màquina")]),_c('br'),_c('br')],1)])],1),_c('b-row',[_c('b-col',[_c('div',[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('table',{staticClass:"table table-striped"},[_c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Description")]),_c('th',[_vm._v("Disk")]),_c('th',[_vm._v("Memory usage")]),_c('th',[_vm._v("CPU usage")]),_c('th',[_vm._v("Uptime")]),_c('th')])]),_c('tbody',_vm._l((_vm.qemus),function(item,i){return _c('tr',{key:i},[_c('th',{attrs:{"scope":"row"}},[_c('img',{class:[
                      item.status == 'running' ? '' : 'filter-gray' ],attrs:{"width":"20px","src":require("../../assets/images/vm.svg")}}),_c('i',[_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_vm._v(" "+_vm._s(item.status))])]),_c('td',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openQemu(item.vmid, item.node)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(_vm._s(_vm.getDiskUsage(item.disk, item.maxdisk)))]),_c('td',[_vm._v(_vm._s(_vm.getMemoryUsage(item.mem, item.maxmem)))]),_c('td',[_vm._v(_vm._s(_vm.getCpuUsage(item.cpu, item.cpus)))]),_c('td',[_vm._v(_vm._s(_vm.getTime(item.uptime)))]),_c('td',[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",class:[
                      item.status == 'stopped'
                        ? 'filter-green'
                        : 'filter-dark-gray' ],attrs:{"title":"Start virtual machine","width":"15px","alt":"start","src":require("../../assets/images/start.svg")},on:{"click":function($event){return _vm.changeStatus(item.vmid, item.node, 'start')}}}),_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",class:[
                      item.status == 'running'
                        ? 'filter-red'
                        : 'filter-dark-gray' ],attrs:{"title":"Stop virtual machine","width":"15px","src":require("../../assets/images/stop.svg")},on:{"click":function($event){return _vm.changeStatus(item.vmid, item.node, 'stop')}}}),_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",attrs:{"title":"Expulsar CD. Si la màquina conté una imatge a l'unitat de CD no estarà en alta disponibilitat.","width":"18px","src":require("../../assets/images/exect.svg")},on:{"click":function($event){return _vm.exectCD(item.vmid, item.node)}}}),_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",class:[
                        item.status == 'running' ? '' : 'filter-gray' ],attrs:{"title":"Open terminal virtual machine","width":"18px","src":require("../../assets/images/logo_powershell.svg")},on:{"click":function($event){return _vm.openVNC(item.vmid, item.node)}}}),_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",attrs:{"title":"Delete virtual machine","width":"18px","src":require("../../assets/images/delete.svg")},on:{"click":function($event){return _vm.showConfirmation(item)}}}),_c('span',{staticStyle:{"display":"inline-block","width":"10px"}}),_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"pointer",attrs:{"title":"Edit virtual machine","width":"18px","src":require("../../assets/images/code-edit.svg")},on:{"click":function($event){return _vm.openQemu(item.vmid, item.node)}}})])])}),0)])])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }