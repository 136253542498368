<template>
  <b-form-group
    label="Sockets:"
    :drag-on-click="true"
    label-cols-sm="2"
    label-align-sm="right"
  >
    <vue-range-slider
      ref="sliderSockets"
      :min="1"
      :height="10"
      :max="4"
      :drag-on-click="true"
      :show="true"
      :speed="0.2"
      v-model="sockets"
    ></vue-range-slider>
    <br />
  </b-form-group>
</template>

<script>
import "vue-range-component/dist/vue-range-slider.css";
import VueRangeSlider from "vue-range-component";
export default {
  props: {
    value: {
      type: Number,
    },
  },
  components: {
    VueRangeSlider,
  },
  created: function () {
    VueRangeSlider.methods.handleKeyup = () => console.log;
    VueRangeSlider.methods.handleKeydown = () => console.log;
  },
  updated() {
    this.$nextTick(function () {
      this.$refs.sliderSockets.refresh();
    });
  },
  computed: {
    sockets: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
</style>