import { Store } from 'vuex';
import * as mutations from './mutations';
import * as getters from './getters';
import * as actions from './actions';

export function createStore() {
    return new Store({
        state() {
            return {
                isAuthenticated: false,               
                user: {
                    id: null,
                    name: '',
                    pool:'',
                    ticket : '',
                    csrfToken : '',
                    tokenTimeStamp : 0
                },
                qemus:[]
            }
        },
        mutations,
        getters,
        actions,
    });
}
