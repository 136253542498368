<template>
  <div class="dashboard-container">
    <b-container class="form-style">
      <br />
      <h2>Nou servei:</h2>
      <hr />
      <qemu-form/>
    </b-container>
  </div>
</template>
<script>
import QemuForm from './components/QemuForm.vue';
export default {
  name: "Qemu",
  computed: {
    routeName() {
      let name = "VM";
      return this.capitalizeFirstLetter(name);
    },
  },
  components: {
    QemuForm,
  },
  data() {
    return {
      busy: false
    };
  },
  created: function () {
    console.log(this.$route.params.id);
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.form-style {
  text-align: left;
  margin-top: 5%;
  max-width: 95%;
}
.ethernet {
  margin-left: 22px;
}
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
.dashboard-container {
  height: auto;
}
.service {
  line-height: 20px;
}
.item-icon {
  height: 48px;
  width: 48px;
}
.server-text {
  color: rgb(37, 37, 37);
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 2px;
  font-weight: 500;
  display: block;
}
.text-info {
  color: #0078d4;
  font-size: small;
}
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>