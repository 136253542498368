<template>
  <div class="dashboard-container">
    <b-container class="form-style">
      <br />
      <h2>Màquina: {{ data.name }}</h2>
      <hr />
      <div style="float: right">
        <b-button class="mx-1" @click="cloneModal()">Clonar</b-button>
        <b-button-group class="mx-1">
          <b-dropdown right text="Afegir">
            <b-dropdown-item @click="addDisk()">Disc</b-dropdown-item>
            <b-dropdown-item @click="$bvModal.show('add-net')"
              >Xarxa</b-dropdown-item
            >
          </b-dropdown>
        </b-button-group>
      </div>
      <br /><br />
      <b-list-group>
        <b-list-group-item class="d-flex align-items-center">
          <img
            v-b-tooltip.hover
            title="CPU"
            style="max-width: 30px"
            class="mr-3"
            src="../../assets/images/memory.svg"
          />

          <span class="mr-auto">Memory</span>
          <span class="mr-auto"
            >{{ data.balloon }}MiB / {{ data.memory }} MiB</span
          >

          <img
            v-if="false"
            v-b-tooltip.hover
            title="Editar"
            width="18px"
            class="pointer"
            src="../../assets/images/code-edit.svg"
            @click="openMemory(data.memory)"
          />
        </b-list-group-item>
        <b-list-group-item class="d-flex align-items-center">
          <img
            v-b-tooltip.hover
            title="CPU"
            class="mr-3"
            style="max-width: 30px"
            src="../../assets/images/cpu.svg"
          />

          <span class="mr-auto">Processors</span>
          <span class="mr-auto"
            >Cores: {{ data.cores }}, Sockets: {{ data.sockets }}</span
          >

          <img
            v-if="false"
            v-b-tooltip.hover
            title="Editar"
            width="18px"
            class="pointer"
            src="../../assets/images/code-edit.svg"
            @click="openProcessors(data.cores, data.sockets)"
          />
        </b-list-group-item>
        <b-list-group-item class="d-flex align-items-center">
          <img
            style="max-width: 30px"
            class="mr-3"
            src="../../assets/images/container.svg"
          />
          <span class="mr-auto">OS</span>
          <span class="mr-auto">{{ data.ide2 }}</span>
          <img
            v-show="data.hasOwnProperty('ide2')"
            v-b-tooltip.hover
            title="Eliminar"
            width="18px"
            class="pointer"
            src="../../assets/images/delete.svg"
            @click="showConfirmation('ide2')"
          /><span style="display: inline-block; width: 10px"></span>
          <img
            v-b-tooltip.hover
            title="Editar"
            width="18px"
            class="pointer"
            src="../../assets/images/code-edit.svg"
            @click="openOS()"
          />
        </b-list-group-item>
        <div v-for="(item, i) in Object.keys(data)" :key="i">
          <b-list-group-item
            class="d-flex align-items-center"
            v-if="item.includes('sata') && item != 'scsihw'"
          >
            <img
              style="max-width: 30px"
              class="mr-3"
              src="../../assets/images/disks.svg"
            />
            <span class="mr-auto">Hard disk</span>
            <span class="mr-auto">{{ item }}:{{ data[item] }}</span>
            <img  v-if="!item.includes('sata0')" 
              v-b-tooltip.hover
              title="Eliminar"
              width="18px"
              class="pointer"
              src="../../assets/images/delete.svg"
              @click="showConfirmation(item)"
            />
          </b-list-group-item>
        </div>
        <div v-for="(item, j) in Object.keys(data)" :key="j + 100">
          <b-list-group-item
            class="d-flex align-items-center"
            v-if="item.includes('net')"
          >
            <img
              style="max-width: 25px"
              class="mr-3"
              src="../../assets/images/networking.svg"
            />
            <span class="mr-auto">Network</span>
            <span class="mr-auto">{{ item }}:{{ data[item] }}</span>
            <img v-if="!item.includes('net0')"
              v-b-tooltip.hover
              title="Eliminar"
              width="18px"
              class="pointer"
              src="../../assets/images/delete.svg"
              @click="showConfirmation(item)"
            /><span style="display: inline-block; width: 10px"></span>
            <img
              v-b-tooltip.hover
              title="Editar"
              width="18px"
              class="pointer"
              src="../../assets/images/code-edit.svg"
              @click="openNet(data[item], item)"
            />
          </b-list-group-item>
        </div>
      </b-list-group>
    </b-container>
    <b-modal
      id="add-disk"
      title="Afegir disc"
      @ok="addDisk"
      @hidden="resetModal"
      size="lg"
    >
      <div class="d-block">
        <b-card-group class="mt-3">
          <disk-component v-model="form.disk" :v="$v.form.disk"
        /></b-card-group>
        <br /><br />
      </div>
    </b-modal>
    <b-modal
      id="add-net"
      title="Afegir xarxa"
      @ok="addNet"
      @hidden="resetModal"
      size="lg"
    >
      <div class="d-block">
        <b-card-group class="mt-3">
          <vlan-component v-model="form.network_selected"
        /></b-card-group>
        <br /><br />
      </div>
    </b-modal>
    <b-modal id="edit-memory" title="Editar memòria" @ok="editMemory" size="lg">
      <div class="d-block">
        <b-card-group class="mt-3">
          <memory-component v-model="form.memory" :v="$v.form.memory" />
        </b-card-group>
        <br /><br />
      </div>
    </b-modal>
    <b-modal id="edit-os" title="Editar OS" @ok="editOS" size="lg">
      <div class="d-block">
        <b-card-group class="mt-3">
          <os-component v-model="form.os_selected" />
        </b-card-group>
        <br /><br />
      </div>
    </b-modal>
    <b-modal
      id="edit-processors"
      title="Editar processador"
      @ok="editProcessor"
      size="lg"
      static
    >
      <div class="d-block">
        <b-card class="mt-3 component-margin" header-tag="header">
          <template #header>
            <div>
              <div class="child inline-block-child">
                <img
                  v-b-tooltip.hover
                  title="CPU"
                  width="20px"
                  src="../../assets/images/cpu.svg"
                />
              </div>
              <div class="child inline-block-child">
                <h6 class="mb-0">CPU</h6>
              </div>
            </div>
          </template>
          <core-component v-model="form.cores" />
          <socket-component v-model="form.sockets" />
        </b-card>
        <br /><br />
      </div>
    </b-modal>
    <b-modal
      id="cloneModal"
      title="Clonar màquina"
      @ok="clone"
      @hidden="resetModal"
      size="lg"
    >
      <h4>Clonar: {{ data.name }}</h4>
      <div class="d-block">
        <name-component v-model="form.name" :v="$v.form.name" />
      </div>
    </b-modal>
    <transition name="fade">
      <Loading v-if="busy" />
    </transition>
  </div>
</template>
<script>
import { required, between, minLength } from "vuelidate/lib/validators";
import NameComponent from "../qemu/components/NameComponent.vue";
import DiskComponent from "./components/DiskComponent.vue";
import VlanComponent from "./components/VlanComponent.vue";
import MemoryComponent from "./components/MemoryComponent.vue";
import CoreComponent from "./components/CoreComponent.vue";
import SocketComponent from "./components/SocketComponent.vue";
import OsComponent from "./components/OSComponent.vue";
export default {
  name: "QemuEdit",
  computed: {
    routeName() {
      let name = "VM";
      return this.capitalizeFirstLetter(name);
    },
  },
  components: {
    NameComponent,
    DiskComponent,
    VlanComponent,
    MemoryComponent,
    CoreComponent,
    SocketComponent,
    OsComponent,
  },
  data() {
    return {
      busy: false,
      data: {},
      os: {},
      net_selected: "",
      form: {
        name: "",
        network_selected: {},
        os_selected: {},
        memory: "1024",
        disk: "1",
        cores: 1,
        sockets: 1,
      },
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(4) },
      memory: { required, between: between(1024, 8192) },
      disk: { required, between: between(1, 30) },
    },
  },
  created: function () {
    this.getQemu(this.$route.params.id, this.$route.params.node);
  },
  methods: {
    async getQemu(vmid, node) {
      this.busy = true;

      await this.$apiCloud(
        "nodes/" + `${node}` + "/qemu/" + `${vmid}` + "/config",
        {
          method: "GET",
        }
      )
        .then((response) => {
          this.data = response.data;
          this.busy = false;
        })
        .catch((err) => {
          this.busy = false;
          this.makeToast("danger", "No hem pogut obtenir la màquina");
        });
    },
    async addDisk() {
      /* De moment no ho deixem afegir disks amb tamany
     this.$v.form.$touch();
      if (
        this.$v.form.$pending ||
        this.$v.form.$error ||
        this.$v.form.$invalid
      ) {
        this.makeToast("danger", "Revisa el formulari");
        return;
      }*/
      let qemuDisks = [];
      Object.keys(this.data).forEach((element) => {
        if (element.includes("sata")) {
          qemuDisks.push(element);
        }
      });
      if (qemuDisks.length >= 4) {
        this.makeToast("danger", "Màxim de 4 discs");
        return;
      }
      this.busy = true;
      let disk = [];
      this.form.disk = 1;
      disk["sata" + this.getNumDisk()] = "PoolAlumnat:" + this.form.disk;
      this.postConfig(disk, "No hem pogut afegir el disk");
    },
    async addNet() {
      if (this.isEmpty(this.form.network_selected)) {
        this.makeToast("danger", "Revisa el formulari");
        return;
      }
      this.busy = true;
      if (this.net_selected) {
        this.updateNet();
        return;
      }
      let net = [];
      net["net" + this.getNumNet()] =
        "e1000,bridge=vmbr" +
        `${this.form.network_selected.value}` +
        ",firewall=1";
      let qemuNets = [];
      Object.keys(this.data).forEach((element) => {
        if (element.includes("net")) {
          qemuNets.push(element);
        }
      });
      if (qemuNets.length >= 4) {
        this.busy = false;
        this.makeToast("danger", "Màxim de 4 targetes de xarxa");
        return;
      }
      this.postConfig(net, "No hem pogut afegir la targeta de xarxa");
    },
    async updateNet() {
      let net = [];
      net[this.net_selected] =
        "e1000,bridge=vmbr" +
        `${this.form.network_selected.value}` +
        ",firewall=1";
      this.update(net, "No hem pogut afegir la targeta de xarxa");
      this.net_selected = "";
    },
    async postConfig(data, msg) {
      await this.$apiCloud(
        "nodes/" +
          `${this.$route.params.node}` +
          "/qemu/" +
          `${this.$route.params.id}` +
          "/config",
        {
          method: "POST",
          body: new URLSearchParams(Object.entries(data)).toString(),
        }
      )
        .then((response) => {
          this.busy = false;
          this.getQemu(this.$route.params.id, this.$route.params.node);
        })
        .catch((err) => {
          this.busy = false;
          this.makeToast("danger", msg);
        });
    },
    openMemory(memory) {
      this.form.memory = memory.toString();
      this.$bvModal.show("edit-memory");
    },
    editMemory() {
      let memory = { memory: this.form.memory, balloon: "512" };
      this.update(memory);
    },
    openProcessors(cores, sockets) {
      this.form.sockets = sockets;
      this.form.cores = cores;
      this.$bvModal.show("edit-processors");
    },
    editProcessor() {
      let processor = { cores: this.form.cores, sockets: this.form.sockets };
      this.update(processor);
    },
    openOS() {
      this.form.os_selected["value"] = this.data.ide2;
      this.form.os_selected["label"] = this.data.ide2;
      this.$bvModal.show("edit-os");
    },
    editOS() {
      let os = {
        ostype: this.getOsType(this.form.os_selected.value),
        ide2: this.form.os_selected.value + ",media=cdrom",
      };
      this.update(os);
    },
    openNet(value, net) {
      this.net_selected = net;
      this.form.network_selected["value"] = value;
      this.form.network_selected["label"] = value;
      this.$bvModal.show("add-net");
    },
    resetModal() {
      this.net_selected = "";
      this.form.name = "";
    },
    getOsType(isoImage) {
      if (isoImage.includes("win") || isoImage.includes("Win")) {
        return "win10";
      } else if (isoImage.includes("ubuntu") || isoImage.includes("linux")) {
        return "l26";
      } else {
        return "l26";
      }
    },
    getNumDisk() {
      try {
        let keys = Object.keys(this.data);
        let diskNumber = [];
        let numbers = [];
        keys.forEach((key) => {
          if (key.includes("sata")) {
            diskNumber.push(parseInt(key.substring(4)));
          }
        });
        numbers = this.missingNumbers(diskNumber.sort());
        if (numbers.length > 0) {
          return numbers[0];
        } else {
          return 5; //max of disks
        }
      } catch (err) {
        return 5; //max of disks
      }
    },
    missingNumbers(numArr) {
      let missing = [];

      for (let i = 1; i < numArr.length; i++) {
        // check where there are gaps
        if (numArr[i] - numArr[i - 1] != 1) {
          let x = numArr[i] - numArr[i - 1];
          let diff = 1;
          while (diff < x) {
            missing.push(numArr[i - 1] + diff);
            diff++;
          }
        }
      }
      return missing;
    },
    getNumNet() {
      try {
        let keys = Object.keys(this.data);
        let maxNet = 0;
        keys.forEach((key) => {
          if (key.includes("net")) {
            let num = parseInt(key.substring(3));
            if (num > maxNet) maxNet = num;
          }
        });
        return maxNet + 1;
      } catch (err) {
        return 10;
      }
    },
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
    showConfirmation(item) {
      this.$bvModal
        .msgBoxConfirm(
          "Per favor confirmes que vols eliminar:   " + this.data[item],
          {
            title: "Confirmació",
            size: "lg",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "SÍ",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.delete(item);
          }
        })
        .catch((err) => {
          this.makeToast("danger", "No hem pogut elimnar");
        });
    },
    async update(data) {
      this.busy = true;
      await this.$apiCloud(
        "nodes/" +
          `${this.$route.params.node}` +
          "/qemu/" +
          `${this.$route.params.id}` +
          "/config",
        {
          method: "PUT",
          body: new URLSearchParams(Object.entries(data)).toString(),
        }
      )
        .then((response) => {
          this.busy = false;
          this.makeToast("success", "Recurs actualitzat correctament");
          this.getQemu(this.$route.params.id, this.$route.params.node);
        })
        .catch((err) => {
          this.makeToast(
            "danger",
            "No hem pogut actulizar els recursos de la màquina."
          );
        });
    },
    async clone() {
      this.busy = true;
      var vm = {
        name: this.$formatQemuName(this.form.name),
        pool: this.$store.state.user.pool,
      };
      await this.$apiCloud(
        "nodes/" +
          `${this.$route.params.node}` +
          "/qemu/" +
          `${this.$route.params.id}` +
          "/clone",
        {
          method: "POST",
          body: new URLSearchParams(Object.entries(vm)).toString(),
        }
      )
        .then((response) => {
          this.busy = false;
          this.makeToast("success", "Màquina clonada");
          setTimeout(() => {
            this.$router.push({
              path: "/dashboard",
            });
          }, 1000);
        })
        .catch((err) => {
          this.busy = false;
          this.makeToast("danger", "No hem pogut clonar la màquina.");
        });
    },
    async cloneModal() {
      this.$bvModal.show("cloneModal");
    },
    async delete(item) {
      this.busy = true;
      var vm = {
        delete: item,
      };
      await this.$apiCloud(
        "nodes/" +
          `${this.$route.params.node}` +
          "/qemu/" +
          `${this.$route.params.id}` +
          "/config",
        {
          method: "PUT",
          body: new URLSearchParams(Object.entries(vm)).toString(),
        }
      )
        .then((response) => {
          this.busy = false;
          this.makeToast("success", "Recurs actualizat correctament");
          this.getQemu(this.$route.params.id, this.$route.params.node);
        })
        .catch((err) => {
          this.makeToast(
            "danger",
            "No hem pogut actulizar els recursos de la màquina."
          );
        });
    },
    makeToast(variant, msg) {
      this.$bvToast.toast(msg, {
        title: "Virtualio.",
        variant: variant,
        toaster: "b-toaster-top-full",
        solid: true,
        autoHideDelay: 5000,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.form-style {
  text-align: left;
  margin-top: 5%;
  max-width: 95%;
}
.ethernet {
  margin-left: 22px;
}
.inline-block-child {
  display: inline-block;
  margin-right: 10px;
}
.dashboard-container {
  height: auto;
}
.service {
  line-height: 20px;
}
.item-icon {
  height: 48px;
  width: 48px;
}
.server-text {
  color: rgb(37, 37, 37);
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 2px;
  font-weight: 500;
  display: block;
}
.text-info {
  color: #0078d4;
  font-size: small;
}
.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }
  .list-item {
    display: inline-block;
    margin-right: 10px;
  }
  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }
  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }
  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }
  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>